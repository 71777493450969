import React from 'react'

export const BulbEmoji = () => (
   <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M15.1461 7.51795C15.1461 11.9509 11.7038 12.6394 11.7038 16.7708C11.7038 18.9037 9.55372 19.0833 7.91726 19.0833C6.50386 19.0833 3.38308 18.547 3.38308 16.7694C3.38308 12.6401 0 11.9516 0 7.51795C0 3.36587 3.6385 0 7.63017 0C11.6232 0 15.1461 3.36587 15.1461 7.51795Z"
         fill="#FFD983"
      />
      <path
         d="M10.4419 22.2787C10.4419 22.8488 8.90393 23.9999 7.57315 23.9999C6.24236 23.9999 4.70435 22.8488 4.70435 22.2787C4.70435 21.7087 6.24167 21.9345 7.57315 21.9345C8.90393 21.9345 10.4419 21.7087 10.4419 22.2787Z"
         fill="#CCD6DD"
      />
      <path
         d="M10.8136 6.98876C10.5445 6.71957 10.1094 6.71957 9.84016 6.98876L7.57307 9.25585L5.30598 6.98876C5.0368 6.71957 4.60169 6.71957 4.33251 6.98876C4.06332 7.25794 4.06332 7.69305 4.33251 7.96223L6.88462 10.5143V17.8024C6.88462 18.1831 7.19305 18.4908 7.57307 18.4908C7.9531 18.4908 8.26153 18.1831 8.26153 17.8024V10.5143L10.8136 7.96223C11.0828 7.69305 11.0828 7.25794 10.8136 6.98876Z"
         fill="#FFCC4D"
      />
      <path
         d="M11.7039 21.2462C11.7039 22.0063 11.087 22.6231 10.327 22.6231H4.8193C4.05924 22.6231 3.44238 22.0063 3.44238 21.2462V17.1155H11.7039V21.2462Z"
         fill="#99AAB5"
      />
      <path
         d="M3.44155 21.9331C3.11109 21.9331 2.81919 21.6942 2.76342 21.3576C2.70077 20.9831 2.95412 20.6278 3.32933 20.5659L11.5908 19.1889C11.9653 19.1215 12.3206 19.3796 12.3825 19.7549C12.4452 20.1294 12.1918 20.4846 11.8166 20.5466L3.55515 21.9235C3.51728 21.9304 3.47873 21.9331 3.44155 21.9331ZM3.44155 19.1793C3.11109 19.1793 2.81919 18.9404 2.76342 18.6038C2.70077 18.2292 2.95412 17.874 3.32933 17.812L11.5908 16.4351C11.9653 16.3683 12.3206 16.6258 12.3825 17.001C12.4452 17.3755 12.1918 17.7308 11.8166 17.7928L3.55515 19.1697C3.51728 19.1765 3.47873 19.1793 3.44155 19.1793Z"
         fill="#CCD6DD"
      />
   </svg>
)
