import React from 'react'

export const useDebounce = (value, delay) => {
   const [debouncedValue, setDebouncedValue] = React.useState(value)

   React.useEffect(() => {
      const handler = setTimeout(() => {
         setDebouncedValue(value)
      }, delay)
      return () => {
         clearTimeout(handler)
      }
   }, [value, delay])

   return debouncedValue
}
