import React from 'react'

export const VerifyEmailIcon = () => {
   return (
      <svg
         width="30"
         height="36"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M30.001 36v-1a6 6 0 00-6-6h-18a6 6 0 00-6 6v1h30z"
            fill="#66757F"
         />
         <path
            d="M9.001 27.482c1.672 1.575 3.746 2.518 6 2.518s4.327-.944 6-2.518V26h-12v1.482z"
            fill="#EF9645"
         />
         <path
            d="M23.751 20.435c1.188.208 2.62.129 2.416.917-.479 1.854-2.604 1.167-2.979 1.188-.375.02.563-2.105.563-2.105z"
            fill="#66757F"
         />
         <path
            d="M24.063 20.645c1.875.25 2.541.416 1.166.958-.772.305-2.243 4.803-3.33 4.118-1.088-.685 2.164-5.076 2.164-5.076z"
            fill="#292F33"
         />
         <path
            d="M6.256 20.435c-1.188.208-2.619.129-2.416.917.479 1.854 2.604 1.167 2.979 1.188.375.02-.563-2.105-.563-2.105z"
            fill="#66757F"
         />
         <path
            d="M5.944 20.645c-1.875.25-2.541.416-1.166.958.772.305 2.243 4.803 3.331 4.118 1.088-.685-2.165-5.076-2.165-5.076z"
            fill="#292F33"
         />
         <path
            d="M5.056 11.03c-1.953 0-2.305 3.165-.664 3.595 0 0-1.367 3.32 1.953 3.32-.547-1.68-1.562-4.414-.781-6.406l-.508-.508zm19.888 0c1.953 0 2.305 3.165.664 3.595 0 0 1.367 3.32-1.953 3.32.547-1.68 1.562-4.414.78-6.406"
            fill="#FFAC33"
         />
         <path
            d="M15.001 28c5.523 0 10-5.597 10-12.5 0-6.904-4.477-12.5-10-12.5s-10 5.596-10 12.5c0 6.903 4.477 12.5 10 12.5z"
            fill="#FFDC5D"
         />
         <path
            d="M11.001 17a1 1 0 01-1-1v-1a1 1 0 012 0v1a1 1 0 01-1 1zm8 0a1 1 0 01-1-1v-1a1 1 0 112 0v1a1 1 0 01-1 1z"
            fill="#662113"
         />
         <path
            d="M16.001 20.5a.5.5 0 01-.5.5h-1a.5.5 0 010-1h1a.5.5 0 01.5.5z"
            fill="#C1694F"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.658 14.788c.148.147.888.59 1.036 1.034.148.443.445 2.954 1.333 3.693.916.762 4.37.478 5.032.149 1.48-.738 1.662-2.798 1.924-3.842.148-.591 1.036-.591 1.036-.591s.888 0 1.036.59c.262 1.045.444 3.105 1.924 3.842.662.33 4.116.614 5.034-.147.887-.74 1.183-3.25 1.331-3.694.146-.443.888-.886 1.035-1.034.148-.148.148-.74 0-.887-.296-.295-3.788-.56-7.548-.148-.75.082-1.035.295-2.812.295-1.776 0-2.062-.214-2.812-.295-3.759-.411-7.252-.148-7.548.148-.149.148-.149.74-.001.887z"
            fill="#292F33"
         />
         <path
            d="M4.859 8.395S6.218-.506 10.791.023c3.512.406 4.89.825 7.833.097 1.947-.482 4.065 1.136 5.342 4.379a27.72 27.72 0 011.224 4.04s3.938-.384 4.165 1.733c.228 2.117-4.354 4.716-15.889 4.716C7.001 14.987.331 12.63.014 10.657c-.317-1.973 4.845-2.262 4.845-2.262z"
            fill="#66757F"
         />
         <path
            d="M5.126 7.15s-.27 1.104-.406 1.87c-.136.769.226 1.297 2.705 1.825 3.287.7 10.679.692 15.058-.383 1.759-.432 2.886-.72 2.751-1.583-.167-1.068-.196-1.066-.541-2.208 0 0-1.477.502-3.427.96-2.66.624-9.964.91-13.481.144-1.874-.41-2.659-.625-2.659-.625zM4.99 21.103c-.354.145 2.921 1.378 7.48 1.458 4.771.084 6.234.39 5.146 1.459-1.146 1.125-.852 2.894-.771 3.418.081.524 2.047 1.916 2.208 2.56.161.645-1.229 5.96-1.229 5.96l-8.729-.251C6.53 26.863 6.212 27.206 4.99 22.103c-.241-1.008 0-1 0-1z"
            fill="#292F33"
         />
         <path
            d="M3.99 21.144c-.354.146 2.921 1.378 7.48 1.458 4.771.084 6.234.39 5.146 1.459-1.146 1.125-.664 2.894-.583 3.418.081.524 1.859 1.916 2.021 2.56.16.645-1.231 5.96-1.231 5.96l-8.729-.251c-2.565-8.844-2.883-8.501-4.105-13.604-.24-1.008.001-1 .001-1z"
            fill="#66757F"
         />
         <path
            d="M25.053 21.103c.354.145-2.921 1.378-7.479 1.458-4.771.084-6.234.39-5.146 1.459 1.146 1.125 2.976 2.892 2.896 3.416-.081.524-4.172 1.918-4.333 2.562-.161.645 1.229 5.96 1.229 5.96l8.729-.251c2.565-8.844 2.883-8.501 4.104-13.604.241-1.008 0-1 0-1z"
            fill="#292F33"
         />
         <path
            d="M25.959 21.103c.354.145-2.921 1.378-7.479 1.458-4.771.084-6.234.39-5.146 1.459 1.146 1.125 2.977 2.892 2.896 3.416-.081.524-4.172 1.918-4.333 2.562-.161.645 1.229 5.96 1.229 5.96l8.657.01c2.565-8.843 2.955-8.762 4.177-13.865.24-1.008-.001-1-.001-1z"
            fill="#66757F"
         />
      </svg>
   )
}
